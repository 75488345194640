import React from 'react';
import { graphql } from 'gatsby';
import Image from '@components/Image';
import MarkdownViewer from '@components/MarkdownViewer';
import isEmpty from 'lodash/isEmpty';
import Tag from 'antd/lib/tag';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import CardCarousel from '@components/CardCarousel';
import SocialSharing from '@components/SocialSharing';
import CardCategory from '@components/Cards/CardCategory';
import AuthorPagePreview from '@components/AuthorPagePreview';
import DesktopAds from '@components/ContentViewer/Advertisement/DesktopAds';
import MobileAds from '@components/ContentViewer/Advertisement/MobileAds';

import formatPapuaDate from '@helpers/methods/formatZuluTime';

import selectContentImage from '@helpers/methods/modifyContents/utils/selectContentImage';
import getNewsCardsProps from '@helpers/methods/modifyContents/getNewsCardsProps';

const NewsArticles = ({ data, location }) => {
  const previewAuthor = data?.strapiAuthorPages;
  let news = data?.strapiNewsArticles;
  news = selectContentImage(news, data?.strapiNewsArticles);

  let otherNews = data?.allStrapiNewsArticles?.nodes;
  otherNews = getNewsCardsProps(otherNews);

  const carouselStyle = otherNews?.length === 1 && 'single-card-wrapper';

  const image = news.metaImage
    ? news.metaImage?.localFile?.childImageSharp?.resize
    : null;

  const sampleDate = formatPapuaDate(news?.published_date);
  const hasNoSampleDate = !sampleDate;
  const actualDate = formatPapuaDate(news?.published_at);
  const newsDate = hasNoSampleDate ? actualDate : sampleDate;

  const coverImageUrl = news?.cover_image?.url
    ? `${process.env.GATSBY_API_URL}${news?.cover_image?.url}`
    : null;

  const thumbnailImageUrl = news?.thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${news?.thumbnail_image?.url}`
    : null;

  return (
    <Layout isMobileComingSoon={false}>
      <SEO title={news?.title} metaImage={image} description="News" />
      <div className="news-page-section _section-padding">
        <div className="news-page">
          <div className="content-and-side-ads-container">
            <div className="content-column">
              <div className="image-container">
                <Image
                  className="desktop-image"
                  image={
                    news?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || coverImageUrl
                  }
                />
                <Image
                  className="phone-image"
                  image={
                    news?.thumbnail_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || thumbnailImageUrl
                  }
                />
              </div>
              <div className="header-wrapper">
                <CardCategory label={news?.news_category?.name} />
                <h1 className="news-title _section-label">{news?.title}</h1>
                <div className="author-info">
                  <div className="author">
                    by{' '}
                    <span className="author-label">
                      {news?.created_by?.username}
                    </span>
                  </div>
                  <div className="_text_grayed_out">&middot;</div>
                  <div className="date _text_grayed_out">
                    <span>{newsDate}</span>
                  </div>
                </div>
              </div>

              <MarkdownViewer
                className={'article-view'}
                source={news?.content}
              />

              <SocialSharing path={location?.pathname} title={news?.title} />

              <div className="tags-container">
                {news?.tags?.map((tag) => (
                  <Tag className="tag-label" key={tag?.name}>
                    {tag?.name}
                  </Tag>
                ))}
              </div>
            </div>

            <DesktopAds />
          </div>

          <AuthorPagePreview
            tagLine={previewAuthor?.details?.tagline}
            authorName={
              previewAuthor?.company_name || news?.created_by?.username
            }
            logo={
              previewAuthor?.avatar?.localFile?.childImageSharp?.gatsbyImageData
            }
            profileLink={
              previewAuthor?.slug ? `/${previewAuthor?.slug}` : '/about'
            }
          />

          <MobileAds />

          {!isEmpty(otherNews) ? (
            <div className="other-news-container">
              <CardCarousel
                title="Other news"
                items={otherNews}
                className={carouselStyle}
                linkRoot="/news/"
              />
            </div>
          ) : (
            <div className="bottom-space" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NewsArticles;

export const query = graphql`
  query NewsArticleQuery(
    $id: Int!
    $categoryId: Int
    $title: String
    $authorId: Int
  ) {
    strapiNewsArticles(strapiId: { eq: $id }) {
      strapiId
      title
      content
      created_at(formatString: "MMMM DD, YYYY")
      published_date
      published_at

      creator_id
      created_by {
        username
      }

      news_category {
        name
      }
      tags {
        name
      }
      metaImage: image {
        localFile {
          childImageSharp {
            resize(width: 1200, height: 628, quality: 100) {
              src
              width
              height
            }
          }
        }
        url
      }

      cover_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      cover_image_old: image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      thumbnail_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      thumbnail_image_old: image_thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }
    }
    allStrapiNewsArticles(
      limit: 6
      sort: { fields: published_date, order: DESC }
      filter: {
        title: { ne: $title }
        news_category: { id: { eq: $categoryId } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        category: news_category {
          name
        }
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        author: created_by {
          username
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: image_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
    }
    strapiAuthorPages(created_by: { id: { eq: $authorId } }) {
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
        url
      }
      company_name
      company_tagline
      slug
    }
  }
`;
