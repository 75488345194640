import React, { useState, useEffect } from 'react';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

const DesktopAds = () => {
  const [adsDatas, setAdsDatas] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.GATSBY_API_URL}/advertisement/2`).then((res) => {
      setAdsDatas(res.data);
    });
  }, []);

  const firstAdInfos = adsDatas[0];
  const secondAdInfos = adsDatas[1];

  const firstAdTitle = firstAdInfos?.ad_title;
  const firstAdLink = firstAdInfos?.ad_square_url;
  const firstAdBanner = firstAdInfos?.ad_square?.url;
  const firstAdBannerUrl = `${process.env.GATSBY_API_URL}${firstAdBanner}`;

  const secondAdTitle = secondAdInfos?.ad_title;
  const secondAdLink = secondAdInfos?.ad_square_url;
  const secondAdBanner = secondAdInfos?.ad_square?.url;
  const secondAdBannerUrl = `${process.env.GATSBY_API_URL}${secondAdBanner}`;

  const noFirstAdBanner = isEmpty(firstAdBanner);
  const noSecondAdBanner = isEmpty(secondAdBanner);

  const firstAds = () => {
    if (noFirstAdBanner) return null;
    return (
      <div className="img-wrapper">
        <a href={firstAdLink} target="_blank" rel="noreferrer noopener">
          <img
            className="desktop-ad"
            src={firstAdBannerUrl}
            alt={firstAdTitle}
          />
        </a>
      </div>
    );
  };

  const secondAds = () => {
    if (noSecondAdBanner) return null;
    return (
      <div className="img-wrapper">
        <a href={secondAdLink} target="_blank" rel="noreferrer noopener">
          <img
            className="desktop-ad"
            src={secondAdBannerUrl}
            alt={secondAdTitle}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="desktop-ads-container">
      {firstAds()}
      {secondAds()}
    </div>
  );
};

export default DesktopAds;
