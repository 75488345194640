import React from 'react';
import { Link } from 'gatsby';
import Button from 'antd/lib/button';
import Image from '@components/Image';

const AuthorPagePreview = ({
  className = '',
  profileLink = '',
  tagLine = '',
  authorName = '',
  logo = null,
}) => {
  const hasEssentialContent = !!tagLine || !!authorName || !!logo;
  const hasProfileLink = !!profileLink;

  return (
    <>
      {hasEssentialContent && (
        <div className={`author-profile-container ${className}`}>
          <div className="author-wrapper">
            {logo ? (
              <div className="photo">
                <Image image={logo} />
              </div>
            ) : (
              <img
                className="photo"
                alt={authorName}
                src="https://wanpng-cms-prod.s3.ap-southeast-2.amazonaws.com/cms/wanpng-user.png"
              />
            )}
            <div className="author-detail-container">
              {authorName && <div className="_author-text">{authorName}</div>}
              {tagLine && <div className="label">{tagLine}</div>}
            </div>
          </div>

          {hasProfileLink && (
            <Link to={profileLink}>
              <Button type="primary" className="primary-btn view-profile-btn">
                View profile
              </Button>
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default AuthorPagePreview;
