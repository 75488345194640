import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const SocialSharing = ({ path = '', title = '' }) => (
  <div className="social-sharing-section">
    <div className="share-icon-container">
      <SpriteIconViewer className="share-icon-wrapper" spriteId="share" />

      <span className="label">Share this:</span>
    </div>
    <div className="social-sharing-icon">
      <a
        href={`https://www.facebook.com/dialog/share?app_id=${process.env.GATSBY_FB_APP_ID}&display=popup&href=${process.env.GATSBY_SITE_URL}${path}&redirect_uri=https://www.facebook.com`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <SpriteIconViewer
          className="icon-wrapper"
          spriteId="facebook_colored"
        />
      </a>

      <a
        href={`https://twitter.com/intent/tweet?&url=${process.env.GATSBY_SITE_URL}${path}`}
        data-size="large"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SpriteIconViewer className="icon-wrapper" spriteId="twitter_colored" />
      </a>

      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.GATSBY_SITE_URL}${path}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <SpriteIconViewer
          className="icon-wrapper"
          spriteId="linkedin_colored"
        />
      </a>

      <a
        href={`mailto:?subject=${title}&body=${process.env.GATSBY_SITE_URL}${path}`}
        rel="noreferrer noopener"
      >
        <SpriteIconViewer className="icon-wrapper" spriteId="email_colored" />
      </a>
    </div>
  </div>
);

export default SocialSharing;
